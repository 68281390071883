// extracted by mini-css-extract-plugin
export var hero = "Hero-module--hero--73f5b";
export var hero__row = "Hero-module--hero__row--ce8e0";
export var hero__image = "Hero-module--hero__image--af99b";
export var hero__content = "Hero-module--hero__content--f3f7c";
export var hero__content__title = "Hero-module--hero__content__title--23745";
export var hero__content__text = "Hero-module--hero__content__text--a3a68";
export var hero__content__button = "Hero-module--hero__content__button--7310c";
export var hero__content__tags = "Hero-module--hero__content__tags--567fd";
export var hero__content__tag = "Hero-module--hero__content__tag--b089f";
export var hero__contentRight = "Hero-module--hero__content--right--71d25";
export var hero__contentRight__subtitle = "Hero-module--hero__content--right__subtitle--9b9fb";
export var hero__contentRight__title = "Hero-module--hero__content--right__title--4c589";
export var hero__contentRight__titleSmall = "Hero-module--hero__content--right__title--small--9c4a1";
export var hero__col = "Hero-module--hero__col--18eab";
export var hero__blockquote = "Hero-module--hero__blockquote--ad023";
export var hero__blockquoteLeft = "Hero-module--hero__blockquote--left--4f786";
export var hero__blockquoteRight = "Hero-module--hero__blockquote--right--af48d";