import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@components/Button";
import * as styles from "./Hero.module.scss";

export const Hero = ({ id, title, text, button, tags, image, subTitle1, title1, subTitle2, title2 }) => {
	const scrollHandler = (e, id) => {
		e.preventDefault();

		let headerHeight = document.querySelector("header").offsetHeight;
		let targetElementOffset = document.querySelector(id).offsetTop;

		window.scrollTo({
			top: targetElementOffset - headerHeight - 50,
			behavior: "smooth",
		});
	};

	return (
		<div id={id} className={`element ${styles.hero}`}>
			<Container>
				<Row className={`${styles.hero__row} justify-content-between align-items-center`}>
					{title && (
						<Col className={styles.hero__content} xs={12} md={6} lg={5} xxl={4}>
							<h1 className={styles.hero__content__title}>{title}</h1>

							{text && <div className={styles.hero__content__text}>{text}</div>}

							{button && (
								<Button
									url={button.url}
									title={button.title}
									target={button.target}
									type="secondary"
									cssClass={styles.hero__content__button}
								/>
							)}

							{tags && (
								<div className={styles.hero__content__tags}>
									{tags.map((tag, i) => (
										<a
											key={`tag-${i}`}
											className={styles.hero__content__tag}
											href={tag.tag.url}
											onClick={(e) => scrollHandler(e, tag.tag.url)}>
											<p
												dangerouslySetInnerHTML={{
													__html: tag.tag.title,
												}}
											/>
										</a>
									))}
								</div>
							)}
						</Col>
					)}

					{image && (
						<Col className={styles.hero__image} xs={12}>
							<GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText} />
						</Col>
					)}

					{title1 && (
						<Col className={styles.hero__col} xs={12} md={6} lg={7} xxl={8}>
							<Row className="justify-content-end">
								<Col className={`${styles.hero__blockquote} ${styles.hero__blockquoteLeft}`}></Col>

								<Col className={`${styles.hero__content} ${styles.hero__contentRight}`}>
									{subTitle1 && <div className={styles.hero__contentRight__subtitle}>{subTitle1}</div>}
									{title1 && <div className={styles.hero__contentRight__title}>{title1}</div>}

									{subTitle2 && <div className={styles.hero__contentRight__subtitle}>{subTitle2}</div>}
									{title2 && <div className={`${styles.hero__contentRight__title} ${styles.hero__contentRight__titleSmall}`}>{title2}</div>}
								</Col>

								<Col className={`${styles.hero__blockquote} ${styles.hero__blockquoteRight}`}></Col>
							</Row>
						</Col>
					)}
				</Row>
			</Container>
		</div>
	);
};

Hero.propTypes = {
	id: PropTypes.any.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	button: PropTypes.object,
	tags: PropTypes.array,
	image: PropTypes.object,
	subTitle1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	subTitle2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	title2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
